import { Box, Button, Chip, IconButton, Modal, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import CloseIcon from "@mui/icons-material/Close";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import { useState } from "react";

const LivenessDeviceTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector((store: RootState) => store.satpasDevice);
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState('')
  const handleClose = () => {
    setOpen(false);
  };

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation();
      setModalText("BASE64 HERE!")
      setOpen(true)
    };

    // REGION: RETURN TSX
    return (
      <>
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onDetail}>
          Detail
        </Button>
      </Stack>
      </>
    );
  };

  const DetailModal = () => {
    return (
      <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: "50%", padding: 5 }}>
        <IconButton
          sx={{ position: "absolute", top: 12, right: 12 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" id="child-modal-title">
          Detail : 
        </Typography>
        <Typography id="child-modal-description">
          {modalText}
        </Typography>
        {/* <Button onClick={handleClose}>Close Child Modal</Button> */}
      </Box>
    </Modal>
    )
  }


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "deviceId",
      headerName: "Device ID",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "hostname",
      headerName: "Hostname",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 225,
    },
    {
      field: "result",
      headerName: "Result",
      flex: 1,
      minWidth: 225,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];


  // REGION: INIT ROWS
  const rows = data.map((row: any) => ({
    uid: row.uc_ocsp_config_uid,
    id: row.uc_ocsp_config_id,
    deviceId: "8094a3e5-e5fd-4647-bd23-efd8c91cbf38",
    hostname: "PCCETAK-1553-G1",
    result: "Live Detected",
    date: "17 Jun 2023 16:14",
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalConfig} />
      <DetailModal />
    </>
  );
};

export default LivenessDeviceTable;
