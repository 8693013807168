import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import PieChart from "../../components/molecules/chart/PieChart";
// import { useGetdashboardHeaderQuery } from "../../store/feature/service/dashboardApiSlice";
import dayjs from "dayjs";
import EChartDoughnut from "../../components/molecules/chart/EChartDoughnut";
import formatNumberWithCommas from "../../utils/hash/dotSparator";
import { getEmail } from "../../utils/storage";

const HeaderDashboard = () => {
  // const { data }: any = useGetdashboardHeaderQuery({});
  const data =  {
    "username": "Autentika Employee",
    "request_details": {
        "total_request": 318053,
        "success_amount": 300111,
        "fail_amount": 17942,
        "success_percentage": 94.3588018349143,
        "fail_percentage": 5.641198165085693
    },
    "device_details": {
        "total_devices": 31,
        "active_amount": 24,
        "inactive_amount": 7,
        "active_percentage": 77.41935483870968,
        "inactive_percentage": 22.58064516129032
    }
}
  const totalRequest = data?.request_details;
  const deviceDetails = data?.device_details;
  const currentDate = dayjs();
  const formattedDate = currentDate.format("YYYY MMM DD");

  // console.log("device details ==> ", deviceDetails);
  // console.log("total Request ===> ", totalRequest);
  const totalDetailsRequest = {
    fail_amount: deviceDetails?.inactive_amount,
    fail_percentage: deviceDetails?.inactive_percentage,
    success_amount: deviceDetails?.active_amount,
    success_percentage: deviceDetails?.active_percentage,
    total_request: deviceDetails?.total_devices,
    name: "Device",
  };

  const newTotalRequest = { ...totalRequest, name: "Request" };

  return (
    <Grid container spacing={3} >
    <Grid item xs={12} md={6}>
      {/* <Paper
        elevation={2}
        sx={{
          p: 4,
          border: "1px solid #ccc",
          height: 300,
          display: "flex",
          flexDirection: "column",
        }}
      > */}
        <Stack spacing={4} alignItems="center">
          <Typography variant="h4">Welcome Back,</Typography>
          <Typography variant="h3">{getEmail()}</Typography>
          <Typography variant="h4">Today Date: {formattedDate}</Typography>
        </Stack>
      {/* </Paper> */}
    </Grid>

    <Grid item xs={12} md={6}>
      {/* <Paper
        elevation={2}
        sx={{
          p: 4,
          border: "1px solid #ccc",
          height: 300,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Total Device</Typography>
          </Grid>
          <Grid item xs={5} alignItems={'center'} justifyContent={'center'} display="flex" >
            <Typography variant="h1">
              {formatNumberWithCommas(deviceDetails?.total_devices || 0)}
            </Typography>
            </Grid>
            <Grid item xs={7}>
            <EChartDoughnut chartData={totalDetailsRequest} />
            </Grid>
        </Grid>
      </Paper> */}
    </Grid>
  </Grid>
  );
};

export default HeaderDashboard;
