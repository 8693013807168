// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const livenessDeviceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLivenessDeviceList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/ocsp/ocsp-configure`,
                    params: params
                }
            },
            providesTags:['GetLivenessDeviceList'],
        })
  })
})

export const {
useGetLivenessDeviceListQuery
} = livenessDeviceApiSlice;