import { Box } from "@mui/system";
import "chart.js/auto";
import {Paper} from "@mui/material";
import DailyStatics from "../../components/molecules/dashboard/DailyStatics";
import HeaderDashboard from "./HeaderDashboard";
import DashboardGrid from "./DashboardGrid";
// import { useGetProfileDetailMutation } from "../../store/feature/service/userApiSlice";
import { useEffect } from "react";

const Dashboard = () => {

  // const [getProfileDetail] = useGetProfileDetailMutation();

  // useEffect(()=> {
  //   getProfileDetail({})
  // }, [])

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <HeaderDashboard />
      {/* <DashboardGrid /> */}
    </Box>
  );
};

export default Dashboard;
