import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllOcspList,
  removeEventFlag,
} from "../../store/feature/satpasDevice";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import LicenseServerSearch from "../../components/molecules/licenseServer/LicenseServerSearch";
import LicenseServerTable from "../../components/molecules/licenseServer/LicenseServerTable";
import { useGetListDashboardMutation } from "../../store/feature/service/mySubscription";

const MySubscription = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const idParam = useParams();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  // const { data: dataCmpSubsUsrData, isLoading } = useGetCmpSubsUsrQuery(params);
  const [
    getListDashboard,
    { data: dataListDash, isSuccess: successListDash, isLoading },
  ] = useGetListDashboardMutation();

  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if (eventFlag) {
  //     dispatch(getAllOcspList(params));
  //     dispatch(removeEventFlag());
  //   }
  // }, [eventFlag]);

  useEffect(() => {
    console.log("hahaha dataCmpSubsUsrData ====> ", dataListDash?.data);
  }, [dataListDash]);

  useEffect(() => {
    if (idParam) {
      getListDashboard(idParam.id);
    }
  }, [idParam]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Dashboard Information
        </Typography>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/license-server/create')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button> */}
        <LicenseServerTable
          data={
            dataListDash !== undefined && dataListDash?.data !== null
              ? dataListDash?.data
              : []
          }
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/my-subscription/card")}
          sx={{ marginRight: 2 }}
        >
          Back
        </Button>
      </Box>
    </ListDataSkeleton>
  );
};

export default MySubscription;
