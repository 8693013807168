import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";

const LogEventsTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector((store: RootState) => store.satpasDevice);

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/log-events/edit/${params.row.uid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            uc_ocsp_config_status: "Deleted",
            uid: params.row.uid
          }
          dispatch(deleteOcspConfiguration(data));
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
      <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
      </Stack>
      </>
    );
  };


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 375,
      headerClassName: "centered",
    },
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "function",
      headerName: "Function",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if( params.row.severity !== null ) {
          switch(params.row.severity.toLowerCase()) {
            case "low": {
              return <Chip label="LOW" color="success" variant="outlined" />
            }
            case "medium": {
              return <Chip label="MEDIUM" color="warning" variant="outlined" />
            }
            case "high": {
              return <Chip label="HIGH" color="secondary" variant="outlined" />
            }
          }
        } else {
          return (
            ""
          )
        }
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      minWidth: 250,
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 320,
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   renderCell: customCellRender,
    // },
  ];


  // REGION: INIT ROWS
  const rows = data.map((row: any) => ({
    id: row.uc_ocsp_config_id,
    uid: row.uc_ocsp_config_uid,
    description: `[UTAPPMGRADMAPI] user successfully login Data ref: {"email":"admin@autentika.id","password":""}`,
    module: "Auth",
    function: "Login",
    severity: "high",
    type: "INFORMATIVE",
    time: "23 Jun 2023 07:16",
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalConfig} />
    </>
  );
};

export default LogEventsTable;
