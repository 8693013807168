import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm, TextAreaForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOcspById, removeEventFlag, getCertificateDropdown } from "../../store/feature/satpasDevice";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import licenseServer from "../../utils/validation/licenseServer";

const LicenseServerField = () => {

  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");
  const {
    editDataOcspConfig,
    isLoadingOcspConfig,
    isLoadingEvent,
    eventFlag
  } = useAppSelector((state: RootState)=> state.satpasDevice)
  const {
    uc_ocsp_config_uid,
   } = editDataOcspConfig
   const optStatusSelect = [
     {
       label: "Active",
       value: "Active",
     },
     {
       label: "Inactive",
       value: "Inactive",
     },
   ];
   
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    serverHostname: string;
    status: string;
    serverUrl: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(licenseServer),
    defaultValues: {
      uid: "",
      serverHostname: "",
      status: "",
      serverUrl: "",
    },
  });


  // REGION: SET FORM DATA
  useEffect(() => {
    if (editFgroup) {
      reset({
        uid: uc_ocsp_config_uid,
        serverHostname: "",
        status: "",
        serverUrl: "",
      });
    } else {
      reset();
    }
  }, [ editDataOcspConfig ]);


  // REGION: DISPATCH GET DATA
  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getOcspById(idParam.id));
    }
    dispatch(getCertificateDropdown({}));
  }, []);


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      navigate("/license-server")
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);


  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    // if (editFgroup) {
    //   const updateData = { }
    //   dispatch(updateOcspConfiguration(updateData));
    // } else {
    //   const createData = { }
    //   dispatch(createOcspConfiguration(createData));
    // }
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
    isLoading={isLoadingOcspConfig}
    isLoadingEvent={isLoadingEvent}
    inputCount={6}
    titleWidth={'350px'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {editFgroup ? "Update License Server" : "Create License Server"}
      </Typography>
      <InputForm
        name="serverHostname"
        label="Server Hostname"
        placeholder="Server Hostname"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={500}
        required
      />
      <SelectFormm
        name="status"
        label="Status"
        defaultValue={0}
        options={optStatusSelect}
        placeholder="none"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
      />
      <InputForm
        name="serverUrl"
        label="Server URL"
        placeholder="Server URL"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        maxLength={500}
        required
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/license-server")}>
          Cancel
        </Button>
        { editFgroup &&
          <Button variant="contained" type="submit">
            Update
          </Button>
        }
        { !editFgroup &&
          <Button variant="contained" type="submit">
            Submit
          </Button>
        }
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default LicenseServerField;
