import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllOcspList,
  removeEventFlag,
} from "../../store/feature/satpasDevice";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import LicenseServerDetailTable from "../../components/molecules/licenseServerDetail/LicenseServerDetailTable";
import dayjs from "dayjs";
import {
  useGetCmpSubsUsrMutation,
  useGetListDashboardMutation,
  usePostAcountConfirmMutation,
  useUpdateCmpSubsStatusMutation,
} from "../../store/feature/service/mySubscription";
import { getEmail } from "../../utils/storage";
import UnsubscribePopUp from "./UnsubscribePopUp";
import Swal from "sweetalert2";

const MySubscribtionCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [bodySubscribed, setBodySubscribed] = useState({});
  const [defaultValue, setDefaultValue] = useState({});
  const [open, setOpen] = useState(false);
  const idParam = useParams();
  const { dataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const currentDate = dayjs();
  const formattedDate = currentDate.format("YYYY MMM DD");

  const [getCmpSubsUsr, { data: dataCmpSubsUsrData, isLoading }] =
    useGetCmpSubsUsrMutation();
  const [
    postAcountConfirm,
    { data: dataAcountConfirm, isSuccess: successAccountConfirm, isLoading: loadingAccountConfirm },
  ] = usePostAcountConfirmMutation();

  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    // dispatch(getAllOcspList(params))
    getCmpSubsUsr({});
  }, [params]);

  const handleUnsubscribed = (item: any) => {
    console.log("item ===> ", item);
    let bodySubscribed = {
      company_subscribe_uid: item.cpn_subscribe_uid,
      product_code: item.prd_code,
      status_middleware: "inactive",
      subscribe_status: "unsubscribed",
      user_status: "active",
    };
    setOpen(true);
    setBodySubscribed(bodySubscribed);
  };

  const handleAccountConfrm = (item: any) => {
    let bodyAccountConfrm = {
      action: item.prd_name.toLowerCase(),
      company_subscribe_uid: item.cpn_subscribe_uid,
    };
    console.log("body ===> ", bodyAccountConfrm);
    postAcountConfirm(bodyAccountConfrm);
  };

  const handleListDashboard = (item: any) => {
    navigate(`/my-subscription/${item.prd_uid}`);
    // getListDashboard(item.prd_uid)
  };

  const handleApiDoc = (item: any) => {
    console.log("item ===> ", item);
  };

  useEffect(() => {
    if (successAccountConfirm) {
      Swal.fire({
        icon: "success",
        title: "Account Confirmation Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Account Confirmation Success",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => {
        getCmpSubsUsr({});
      });
    }
  }, [successAccountConfirm]);

  const CircularImage = ({ item }: any) => {
    const imageUrl = "https://via.placeholder.com/150"; // Replace with your image URL

    return (
      <Container>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6} md={5}>
            <Avatar
              alt="Example Image"
              src={imageUrl}
              sx={{ width: 90, height: 90 }}
            />
          </Grid>
          <Grid item xs={6} md={7}>
            <Typography variant="h6">{item.prd_name}</Typography>
            <Typography variant="body1">{item.prc_plan_config_name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Subscribe date :{" "}
              {dayjs(item.cpn_subscribe_at).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  // REGION: RETURN TSX
  const CardSlice = ({ item }: any) => {
    return (
      <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
        <Paper
          elevation={2}
          sx={{
            p: 4,
            border: "1px solid #ccc",
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} textAlign={"right"}>
              <Typography
                variant="h5SemiBold"
                color={
                  item.cpn_subscribe_status === "unsubscribed" ? "red" : "green"
                }
              >
                {item.cpn_subscribe_status.charAt(0).toUpperCase() +
                  item.cpn_subscribe_status.slice(1)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <CircularImage item={item} />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" alignItems="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={item.cpn_subscribe_status === "unsubscribed"}
                  onClick={() => handleApiDoc(item.prd_api_documentation_link)}
                >
                  API Documentation
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => handleListDashboard(item)}
                  disabled={item.cpn_subscribe_status === "unsubscribed"}
                >
                  List Dashboard
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" alignItems="center">
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  disabled={item.cpn_subscribe_status === "unsubscribed"}
                  onClick={() => handleUnsubscribed(item)}
                >
                  Unsubscribe
                </Button>
                {(item.cpn_register_to_prod === "unregistered" &&
                  item.prd_code === "tokn") ||
                (item.cpn_register_to_prod === "unregistered" &&
                  item.prd_code === "wsop") ? ( // unregistered dan (tokn || wsop) = prd_code, selain itu jangan munuculin buttonnya
                  <Button
                    variant="contained"
                    size="large"
                    color="warning"
                    disabled={item.cpn_subscribe_status === "unsubscribed"}
                    onClick={() => handleAccountConfrm(item)}
                  >
                    Account Confirmation
                  </Button>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };
  return (
    <ListDataSkeleton
      isLoading={false}
      isLoadingEvent={loadingAccountConfirm}
      titleWidth={"300px"}
    >
    <Box>
      <Grid container spacing={3} p={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            My Subscription
          </Typography>
        </Grid>
        {dataCmpSubsUsrData?.data !== null ? dataCmpSubsUsrData?.data.map((item: any, index: any) => (
          <CardSlice key={index} item={item} />
        )) : 'No Data'}
        <UnsubscribePopUp
          open={open}
          setOpen={setOpen}
          bodySubscribed={bodySubscribed}
          getCmpSubsUsr={getCmpSubsUsr}
        />
      </Grid>
    </Box>
    </ListDataSkeleton>
  );
};

export default MySubscribtionCard;
