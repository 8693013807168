import { encrypt } from "../../../utils/hash/password";
// import { apiAdminSlice } from "../api/apiAdminSlice";
import { apiSlice } from "../api/apiSlice";
// import { ILogin } from "../userReducer";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        submitLogin: builder.mutation<any, any>({
            query: credentials => {
                const { Email, Password } = credentials;
                const pasEnc = encrypt(Password);
                return ({
                    url: '/auth/login',
                    method: 'POST',
                    body: {
                        username: Email,
                        password: Password,
                    },
                })
            },
            invalidatesTags:['submitLogin']
        }),
        logout: builder.mutation<any, any>({
            query: (refreshToken) => {
                return ({
                    url: '/auth/logout',
                    method: 'POST',
                })
            },
            invalidatesTags:['submitLogin']
        })
    })
})

export const { useSubmitLoginMutation, useLogoutMutation } = userApiSlice