import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputForm } from "../../components/molecules/forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import unSubscribed from "../../utils/validation/unSubscribed";
import {
  useGetCmpSubsUsrMutation,
  useUpdateCmpSubsStatusMutation,
} from "../../store/feature/service/mySubscription";
import Swal from "sweetalert2";

const UnsubscribePopUp = ({ open, setOpen, bodySubscribed, getCmpSubsUsr }: any) => {
  // const [open, setOpen] = useState(false)
  const [
    updateCmpSubsStatus,
    {
      data: dataUpdtSubs,
      isSuccess: successUpdtSubs,
      isError: errorUpdtSubs,
      error: errorDataSubs,
    },
  ] = useUpdateCmpSubsStatusMutation();

  // const [getCmpSubsUsr, { data: dataCmpSubsUsrData, isLoading }] =
  //   useGetCmpSubsUsrMutation();

  const closeModal = () => {
    setOpen(false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    subscribe: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(unSubscribed),
    defaultValues: {
      subscribe: "",
    },
  });

  const onSubmit = (e: any) => {
    let subscribeData = {
      ...bodySubscribed,
      ["user_email"]: e.subscribe,
    };
    updateCmpSubsStatus(subscribeData);
    setOpen(false);
  };

  useEffect(() => {
    if (successUpdtSubs) {
      Swal.fire({
        icon: "success",
        title: "Success Unsubscribe",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Unsubscribe",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      })
      .then(()=> getCmpSubsUsr({}))
    }
  }, [successUpdtSubs]);

  useEffect(() => {
    if (errorUpdtSubs) {
      const error: any = errorDataSubs;
      // console.log("errorDataSubs ==> ", error?.data?.error_details?.detail);
      Swal.fire({
        icon: "error",
        title: "Error Unsubscribe",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: error?.data?.error_details?.detail,
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [errorUpdtSubs]);

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "20px",
          width: "40%",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} mb={1}>
          <IconButton onClick={() => closeModal()}>
            <Close
              sx={{
                height: "20px",
                width: "20px",
                padding: "0px",
                alignSelf: "center",
              }}
            />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <WarningAmberIcon sx={{ color: "red", marginRight: 1 }} />
          <Typography variant="Subtitle16Bold">Unsubscribe</Typography>
        </Box>
        <Box sx={{ padding: "30px" }}>
          <InputForm
            name="subscribe"
            label="Please Type Your Email Below"
            control={control}
            errors={errors}
            required
            maxLength={100}
            isLoading={false}
            placeholder="Type Email .."
          />
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{ marginLeft: "auto", marginRight: "8px" }}
              variant="outlined"
              color="error"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button variant="contained" color="error" type="submit">
              Unsubscribe
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnsubscribePopUp;
