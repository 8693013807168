import IRoutes from "../interfaces/IRoutes";
import LivenessDevice from "../pages/livenessDevice/LivenessDevice";

const LivenessDeviceRoute: Array<IRoutes> = [
  {
    element: <LivenessDevice />,
    path: "/liveness-device",
  },
];

export { LivenessDeviceRoute };
