import IRoutes from "../interfaces/IRoutes";
import LicenseServer from "../pages/mySubscription/MySubscription";
import LicenseServerDetail from "../pages/mySubscription/MySubscribtionCard";
import LicenseServerDetailField from "../pages/mySubscription/LicenseServerDetailField";
import LicenseServerField from "../pages/mySubscription/MySubscriptionField";
import MySubscribtionCard from "../pages/mySubscription/MySubscribtionCard";

const MySubscribtionRoute: Array<IRoutes> = [
    {
        element: <LicenseServer />,
        path: "/my-subscription/:id",
    },
    {
        element: <LicenseServerField />,
        path: "/my-subscription/create",
    },
    {
        element: <MySubscribtionCard />,
        path: "/my-subscription/card",
    },
    {
        element: <LicenseServerDetailField />,
        path: "/my-subscription/license-detail/:id/create",
    },
];


export { MySubscribtionRoute };
