import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridEventListener,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/satpasDevice";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { getRefreshToken, getToken } from "../../../utils/storage";

const LicenseServerTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { totalConfig } = useAppSelector(
  //   (store: RootState) => store.satpasDevice
  // );

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/license-server/license-detail/${params.row.uid}`);
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onDetail}>
            Detail
          </Button>
        </Stack>
      </>
    );
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    let url = params.row.url;

    // Check if the URL starts with 'www.'
    const startsWithWWW = url.startsWith("www.");

    if (startsWithWWW) {
      url = `http://${url}`;
      // url = `http://localhos:3001?token=${getToken()}`;
    } else {
      // Check if the URL is absolute
      const isAbsoluteUrl = /^https?:\/\//i.test(url);

      if (!isAbsoluteUrl) {
        // Ensure that relative URLs start with a slash
        if (!url.startsWith("/")) {
          url = "/" + url;
        }
        // Assuming your base URL is 'http://localhost:3000', replace it with your actual base URL if different
        const baseUrl = window.location.origin;
        url = `${baseUrl}${url}`;
      }
    }

    if (url) {
      const urlWithoutSlice = url.slice(0, -1);
      if (params.row.dashboardName.toLowerCase() === "wsop") {
        window.open(
          `${urlWithoutSlice}?token=${getToken()}&refresh_token=${getRefreshToken}`,
          "_blank"
        );
      } else {
        window.open(url, "_blank");
      }
    } else {
      console.error("Invalid URL:", url);
    }
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "dashboardName",
      headerName: "Dashboard Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "url",
      headerName: "URL",
      flex: 2,
      minWidth: 250,
    },
  ];

  // REGION: INIT ROWS
  const rows = data?.map((row: any) => ({
    uid: row.prd_dashboard_link_uid,
    id: row.prd_dashboard_link_uid,
    dashboardName: row.prd_dashboard_link_name,
    url: row.prd_dashboard_link_url,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          onRowClick={handleRowClick}
        />
      </Box>
      {/* <PaginationControl
        params={params}
        setParams={setParams}
        total={totalConfig}
      /> */}
    </>
  );
};

export default LicenseServerTable;
