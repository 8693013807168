// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const licenseServerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCmpSubsUsr: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/companies-subscribe/user`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetCmpSubsUsr"],
    }),
    updateCmpSubsStatus: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/companies-subscribe/update-customer-status`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateCmpSubsStatus", "GetCmpSubsUsr"],
    }),
    postAcountConfirm: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/registerEntrustService`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["PostAcountConfirm", "GetCmpSubsUsr"],
    }),
    getListDashboard: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/companies-subscribe/dashboard-link/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetLishDashboard"],
    }),
  }),
});

export const {
  useGetCmpSubsUsrMutation,
  useUpdateCmpSubsStatusMutation,
  usePostAcountConfirmMutation,
  useGetListDashboardMutation,
} = licenseServerApiSlice;

// submitLogin: builder.mutation<any, any>({
//     query: credentials => {
//         const { Email, Password } = credentials;
//         const pasEnc = encrypt(Password);
//         return ({
//             url: '/auth/login',
//             method: 'POST',
//             body: {
//                 username: Email,
//                 password: Password,
//             },
//         })
//     },
//     invalidatesTags:['submitLogin']
// })
