import {
  Box,
  // TablePagination,
  Pagination,
  Typography,
  // OutlinedInput,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../../store";
import InputForm from "../forms/inputForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SelectForm from "../forms/selectFormm";
import { useLocation } from "react-router-dom";
import { getAllOcspList } from "../../../store/feature/satpasDevice";
// import { getServMngTable } from "../../../store/feature/servMng";

const PaginationControl = ({ params, setParams, total }: any) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  // const [pagination, setPagination] = useState({
  //   count: 0,
  //   from: 0,
  //   to: page,
  // });
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { pathname } = useLocation();

  const formOcspConfig = pathname === "/satpas-device" ? true : false;
  const schema = yup.object({
    goTo: yup.number().required(),
  });
  const {
    control,
    formState: { errors },
  } = useForm<{
    goTo: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      goTo: 0,
    },
  });

  const handlePerPage = (e: any) => {
    setPagesize(e.value);
  };

  useEffect(() => {
    let tempPage = page;
    const totalPage = calculateTotalPage();
    if (totalPage < page) {
      tempPage = totalPage;
    }
    if (formOcspConfig) {
      dispatch(
        getAllOcspList({
          ...params,
          page: tempPage,
          pagesize,
        })
      );
    } else {
      // dispatch(getServMngTable({
      //   ...params,
      //     page: tempPage,
      //     pagesize,
      // }))
    }
  }, [page, pagesize]);

  const handlePaginate = (event: any, page: any) => {
    setPage(page);
  };

  const calculateTotalPage = () => {
    return Math.ceil(total / pagesize);
  };

  // const totalRecord = () => {
  //   let total = 0;
  //   if (formOcspConfig) {
  //     total = totalConfig
  //   } else {
  //     // total = totalRecServMng
  //   }
  //   return total;
  // };

  const handlePageGoto = (e: any) => {
    if (e === "" || e === undefined) {
      setPage(1);
      return;
    }
    setPage(e);
  };
  const optSelect = [
    {
      label: "10/page",
      value: 10,
    },
    {
      label: "20/page",
      value: 20,
    },
    {
      label: "30/page",
      value: 30,
    },
    {
      label: "50/page",
      value: 50,
    },
    {
      label: "100/page",
      value: 100,
    },
  ];

  return (
    <Box
      sx={{
        mt: '20px',
        flexDirection: "row",
        paddingy: "13px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingRight: "20px",
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "170px",
        }}
      >
        <Typography
          variant="paragraph1"
          fontFamily="Open Sans"
          paddingBottom={"15px"}
        >
          Total {total}
        </Typography>
        <SelectForm
          name="perPage"
          defaultValue={1}
          options={optSelect}
          placeholder="10/page"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          onChangeForm={handlePerPage}
        />
      </Box>

      <Pagination
        count={calculateTotalPage()}
        variant="outlined"
        shape="rounded"
        onChange={handlePaginate}
        sx={{ mb: "10px" }}
        size="large"
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="body1"
          fontFamily="Open Sans"
          paddingBottom={"15px"}
        >
          Go to
        </Typography>
        <InputForm
          onChange={handlePageGoto}
          name="goTo"
          required
          maxLength={3}
          control={control}
          disabled={false}
          errors={errors}
          isLoading={false}
          sxFL={{ color: "red", backgroundColor: "red" }}
          endAdornment={<Typography variant="body16Regular"></Typography>}
          sxEndAdornment={{ backgroundColor: "red" }}
        />
      </Box>
    </Box>
  );
};

export default PaginationControl;
