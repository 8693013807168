import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from '../pagination/Pagination';
import Swal from 'sweetalert2';

const SatpasDeviceTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector(
    (store: RootState) => store.satpasDevice
  );

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/satpas-device/edit/${params.row.uid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: 'Delete Confirmation',
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#808080',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'deviceId',
      headerName: 'Device ID',
      flex: 1,
      minWidth: 250,
      headerClassName: 'centered',
    },
    {
      field: 'hostname',
      headerName: 'Hostname',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'enrolledDate',
      headerName: 'Enrolled Date',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if (params.row.status !== null) {
          switch (params.row.status.toLowerCase()) {
            case 'active': {
              return <Chip label="ACTIVE" color="success" variant="outlined" />;
            }
            case 'inactive': {
              return (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              );
            }
            case 'registered': {
              return (
                <Chip label="REGISTERED" color="primary" variant="outlined" />
              );
            }
          }
        } else {
          return '';
        }
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   minWidth: 320,
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   renderCell: customCellRender,
    // },
  ];

  // REGION: INIT ROWS
  const rows = data?.map((row: any) => ({
    uid: row.uc_ocsp_config_uid,
    id: row.uc_ocsp_config_id,
    deviceId: 'e4abede6-b2e8-42fc-bd30-4c0224639664',
    hostname: 'AIDEV-TEST',
    enrolledDate: '15 Feb 2023 17:36',
    status: 'registered',
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalConfig}
      />
    </>
  );
};

export default SatpasDeviceTable;
