import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  getAllOcspList,
  removeEventFlag,
} from '../../store/feature/satpasDevice';
import SatpasDeviceSearch from '../../components/molecules/satpasDevice/SatpasDeviceSearch';
import SatpasDeviceTable from '../../components/molecules/satpasDevice/SatpasDeviceTable';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const SatpasDevice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
    (state: RootState) => state.satpasDevice
  );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data, isLoading } = useGetAllOcspListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if (eventFlag) {
  //     dispatch(getAllOcspList(params));
  //     dispatch(removeEventFlag());
  //   }
  // }, [eventFlag]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Satpas Device
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/satpas-device/create')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button>
        <SatpasDeviceSearch params={params} setParams={setParams} />
        <SatpasDeviceTable
          data={data !== undefined ? data?.data?.data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default SatpasDevice;
