import IRoutes from "../interfaces/IRoutes";
import SatpasDevice from "../pages/satpasDevice/SatpasDevice";
import SatpasDeviceField from "../pages/satpasDevice/SatpasDeviceField";

const SatpasDeviceRoute: Array<IRoutes> = [
  {
    element: <SatpasDevice />,
    path: "/satpas-device",
  },
  {
    element: <SatpasDeviceField />,
    path: "/satpas-device/create",
  },
  {
    element: <SatpasDeviceField />,
    path: "/satpas-device/edit/:id",
  }
];

export { SatpasDeviceRoute };
