import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";

const schema = yup.object({
  title: yup.string().required(),
});

const LivenessDeviceSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [hostname, setHostname] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [result, setResult] = useState(""); // TODO: NEED PARAMETER FROM API
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    deviceId: string;
    hostname: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      deviceId: "",
      hostname: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  const handleDeviceId = (e: string) => { setDeviceId(e); };
  const handleHostnameSelect = (e: any) => { setHostname(e); };
  const handleResultSelect = (e: any) => { setResult(e.value); }; 
  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setDateStart(NewDate);
  };
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setDateEnd(NewDate);
  };

  useDebouncedEffect(() => setSearchFilterDebounced(deviceId), [deviceId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(hostname), [hostname], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(result), [result], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateStart), [dateStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(dateEnd), [dateEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      param_filter_device_id: deviceId,
      param_filter_hostname: hostname,
      param_filter_date_start: dateStart,
      param_filter_date_end: dateEnd,
      param_filter_result: result, 
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="deviceId"
          errors={errors}
          control={control}
          required
          placeholder="Device ID"
          maxLength={100}
          isLoading={false}
          onChange={handleDeviceId}
        />
        <InputForm
          name="hostname"
          errors={errors}
          control={control}
          required
          placeholder="Hostname"
          maxLength={100}
          isLoading={false}
          onChange={handleHostnameSelect}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateEnd}
        />
        <SelectForm 
          name="result"
          defaultValue={1}
          options={optSelect}
          placeholder="Result"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleResultSelect}
        />
      </Box>
    </Box>
  );
};

export default LivenessDeviceSearch;
