import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import userReducer from "./feature/user";
// import satpasDevice from "./feature/satpasDevice";
import { apiSlice } from "./feature/api/apiSlice";
import { satpasDeviceReducer } from "./feature/satpasDeviceReducer";
import { satpasDeviceApiSlice } from "./feature/service/satpasDeviceApiSlice";
import { userReducer } from "./feature/reducer/userReducer";

// export const store = configureStore({
//   reducer: {
//     user: userReducer,
//     satpasDevice: satpasDevice
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

export const reducer = combineReducers({
  satpasDevice: satpasDeviceReducer,
  user: userReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export type RootState = ReturnType<typeof reducer>;


export const createStore = () =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      // if (isDevelopment()) {
      //   return getDefaultMiddleware()
      //     .concat(apiSlice.middleware)
      //     .concat(logger);
      // }
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
