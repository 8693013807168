import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { clearStorage, getRefreshToken, getToken } from "../../../utils/storage";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_API}/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    console.log('get state ===> ', endpoint)
    const token = getToken();
    const refreshToken = getRefreshToken()
    if (process.env.NODE_ENV === "development") {
      headers.set("x-mock-response-code", "200");
    }
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    if(endpoint === 'logout'){
      headers.set('Refresh_token', refreshToken)
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  let resp: any = result?.error?.status;
  if (resp === 401) {
    clearStorage();
    window.location.href = "/login";
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "GetAllOcspList",
    "GetAdminManagementList",
    "GetCmpSubsUsr",
    "GetLivenessDeviceList",
    "GetLogEventsList",
    "submitLogin",
    "UpdateCmpSubsStatus",
    "PostAcountConfirm",
    "GetLishDashboard"
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
