// import { Button, Grid, Box, Stack, Typography } from '@mui/material';
// import { Container } from '@mui/system';
// import { useEffect, useMemo, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import InputForm from '../../components/molecules/forms/inputForm';
// import type { RootState } from '../../store';
// import { useForm } from 'react-hook-form';
// import { useAppDispatch, useAppSelector } from '../../store';
// import { yupResolver } from '@hookform/resolvers/yup';
// import LoadingButton from '@mui/lab/LoadingButton';
// import SaveIcon from '@mui/icons-material/Save';
// import LoginSchema from '../../utils/validation/login';
// import { useNavigate } from 'react-router-dom';
// import { setEmail, setToken } from '../../utils/storage';
// import { useSubmitLoginMutation } from '../../store/feature/service/userApiSlice';
// import Swal from 'sweetalert2';
// import { MailOutline as MailOutlineIcon, Lock as LockIcon } from "@mui/icons-material";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import indonesiaImg from '../../assets/logo/Indonesia.png';
// import korlantasImg from '../../assets/logo/korlantas-logo.png';

// const Login = () => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   const [isShowPass, setIsShowPass] = useState(false);
//   const [dataLogin, setDataLogin] = useState<any>({})
//   const [submitLogin, { error: errorLogin, isLoading, isError, isSuccess }] = useSubmitLoginMutation();
//   const { accesToken } = useAppSelector(
//     (state: RootState) => state.user
//   );

// interface ILogin {
//   email: string;
//   password: string;
//   showPass: boolean;
// }

//   const {
//     control,
//     handleSubmit,
//     formState: { errors },
//   } = useForm<ILogin>({
//     reValidateMode: 'onChange',
//     resolver: yupResolver(LoginSchema),
//     defaultValues: {
//       email: '',
//       password: '',
//       showPass: false,
//     },
//   });

//   const onSubmit = async (data: ILogin) => {
//     setDataLogin(data)
//     submitLogin(data)
//   };
//   const endAdornment = useMemo(() => {
//     if (isShowPass) {
//       return <VisibilityIcon />;
//     }
//     return <VisibilityOffIcon />;
//   }, [isShowPass]);

//   const toggleShowPassword = () => {
//     setIsShowPass((prev) => !prev);
//   };

//   useEffect(() => {
//     if(isError) {
//       let message = "Error Login, Please try again"
//       try {
//         const dataError: any = errorLogin
//         message = dataError?.data.errors[0].detail
//         if(message == "user not found") {
//           message = "Incorrect username/password combination. Please check and try again"
//         }
//       } catch(e){}
//       Swal.fire({
//         icon: "error",
//         title: "Error Login",
//         showCancelButton: false,
//         confirmButtonText: "OK",
//         confirmButtonColor: "#051438",
//         text: message,
//         didOpen: () => Swal.getConfirmButton()?.focus(),
//       });
//     }
//   }, [isError]);

//   useEffect(()=> {
//     if(isSuccess){
//       setEmail(dataLogin.Email)
//     }
//   }, [isSuccess])

//   useEffect(() => {
//     if (accesToken) {
//       setToken(accesToken)
//       navigate('/', { replace: true });
//     }
//   }, [accesToken]);

//   const theme = createTheme({
//     palette: {
//       primary: {
//         main: '#051438', // Change this to your desired color
//       },
//     },
//   });

//   return (
//     <div style={{
//         backgroundImage: `url(${indonesiaImg})`,
//         backgroundSize: 'cover',
//         backgroundColor: "#051438",
//         height: 'auto',
//         maxWidth: '100%'
//       }}>
//       <Container maxWidth="sm">
//         <Grid
//           container
//           spacing={2}
//           direction="column"
//           justifyContent="center"
//           alignItems="center"
//           sx={{ minHeight: '105vh', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
//         <Stack
//           style={{
//             display: 'grid',
//             placeItems: 'center', // Centers both horizontally and vertically
//           }}
//         >
//           <Box
//             bgcolor="#FFFFFF"
//             borderRadius={'50%'}
//             boxShadow={3}
//             width={'100px'}
//             height={'100px'}
//             textAlign="center"
//             marginBottom={'-50px'}
//             zIndex={1000}
//             display= {'flex'}
//             alignItems= {'center'}
//             justifyContent= {'center'}
//             >
//               <img src={korlantasImg} height={'75px'} width={'75px'}/>
//             </Box>
//           <Box
//             pt={9}
//             pb={5}
//             pl={5}
//             pr={5}
//             bgcolor="#FFFFFF"
//             borderRadius={4}
//             boxShadow={3}
//             maxWidth="500px"
//             width="100%"
//             textAlign="center"
//             >
//             <Grid
//               container
//               direction="column"
//               component="form"
//               onSubmit={handleSubmit(onSubmit)}
//             >
//               <Grid item>
//                 <Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
//                   Welcome to UnoHub Client
//                 </Typography>
//               </Grid>
//               <Grid
//               item >
//                 <InputForm
//                   name="Email"
//                   errors={errors}
//                   control={control}
//                   required
//                   placeholder="Email"
//                   maxLength={100}
//                   isLoading={false}
//                   sxEndAdornment={{
//                     backgroundColor: 'inherit',
//                     border: 'none',
//                     cursor: 'pointer',
//                     width: '4rem',
//                   }}
//                   showStartAdornment
//                   startAdornment={<MailOutlineIcon />}
//                   sxFC={{ marginBottom: '1rem'}}
//                 />
//               </Grid>
//               <Grid item>
//                 <InputForm
//                   name="Password"
//                   errors={errors}
//                   control={control}
//                   type={isShowPass ? 'text' : 'password'}
//                   placeholder="Password"
//                   maxLength={127}
//                   showEndAdornment
//                   sxEndAdornment={{
//                     backgroundColor: 'inherit',
//                     border: 'none',
//                     cursor: 'pointer',
//                     width: '4rem',
//                   }}
//                   endAdornment={endAdornment}
//                   onClickEndAdornment={toggleShowPassword}
//                   required
//                   showStartAdornment
//                   startAdornment={<LockIcon />}
//                   sxFC={{ marginBottom: '1.5rem'}}
//                 />
//               </Grid>
//               <Grid item>
//                  {isLoading ? (
//                 <ThemeProvider theme={theme}>
//                   <LoadingButton
//                     loading
//                     loadingPosition="start"
//                     startIcon={<SaveIcon />}
//                     variant="outlined"
//                     fullWidth
//                     color="secondary"
//                     sx={{ borderRadius: '12px', textTransform: 'none' }}
//                   >
//                   Login
//                   </LoadingButton>
//                 </ThemeProvider>
//                 ) : (
//                   <ThemeProvider theme={theme}>
//                   <Button
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     sx={{ borderRadius: '12px', textTransform: 'none' }}
//                     >
//                     Login
//                   </Button>
//                 </ThemeProvider>
//                  )}
//               </Grid>
//             </Grid>
//           </Box>
//         </Stack>
//         </Grid>
//       </Container>
//     </div>
//   );
// };

// export default Login;

import { Button, Grid, Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputForm from "../../components/molecules/forms/inputForm";
import type { RootState } from "../../store";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import LoginSchema from "../../utils/validation/login";
import { useNavigate } from "react-router-dom";
import { setEmail, setToken } from "../../utils/storage";
import {
  MailOutline as MailOutlineIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import indonesiaImg from "../../assets/logo/Indonesia.png";
import autentikaImg from "../../assets/logo/medium.png";
import { useTheme } from "@mui/material/styles";
import { useSubmitLoginMutation } from "../../store/feature/service/userApiSlice";
import Swal from "sweetalert2";

interface ILogin {
  email: string;
  password: string;
  showPass: boolean;
  Email: string;
}

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isShowPass, setIsShowPass] = useState(false);
  const [
    submitLogin,
    { data: dataLogin, error: errorLogin, isLoading, isError },
  ] = useSubmitLoginMutation();
  const { accesToken } = useAppSelector((state: RootState) => state.user);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    reValidateMode: "onChange",
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
      showPass: false,
    },
  });

  const onSubmit = async (data: ILogin) => {
    setEmail(data?.Email);
    submitLogin(data);
  };
  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  useEffect(() => {
    if (accesToken) {
      setToken(accesToken);
      navigate("/", { replace: true });
    }
  }, [accesToken]);

  useEffect(() => {
    if (errorLogin) {
      if ("data" in errorLogin) {
        const dataError =
          "error" in errorLogin
            ? errorLogin.error
            : JSON.stringify(errorLogin.data);
        const dataErrorJson = JSON.parse(dataError);
        console.log("error bor", dataErrorJson);
        if (dataErrorJson.error_description === "Account is not fully set up") {
          Swal.fire({
            icon: "error",
            title: "Error Login",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            html: 'Please change password by this link first <a href="http://10.10.10.92:6001/login" target="_blank">http://10.10.10.92:6001/login</a>, then login again to this tab.',
            didOpen: () => Swal.getConfirmButton()?.focus(),
          }).then(() => {
            window.location.href = "http://10.10.10.92:6001/login";
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Login",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: "#051438",
            text: dataErrorJson.error_description,
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      }
    }
  }, [errorLogin]);

  return (
    <div
      style={{
        backgroundImage: `url(${indonesiaImg})`,
        backgroundSize: "cover",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          sx={{ minHeight: "105vh" }}
        >
          <Stack
            style={{
              display: "grid",
              placeItems: "center", // Centers both horizontally and vertically
            }}
          >
            <Box
              bgcolor="#FFFFFF"
              borderRadius={"50%"}
              boxShadow={3}
              width={"100px"}
              height={"100px"}
              textAlign="center"
              marginBottom={"-50px"}
              zIndex={1000}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={autentikaImg} height={"65px"} width={"65px"} />
            </Box>
            <Box
              pt={9}
              pb={5}
              pl={5}
              pr={5}
              bgcolor="#FFFFFF"
              borderRadius={4}
              boxShadow={3}
              maxWidth="500px"
              width="100%"
              textAlign="center"
            >
              <Grid
                container
                direction="column"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                  >
                    Welcome to UnoHub Client
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "0.5rem",
                      color: "#83a4c9",
                    }}
                  >
                    Dashboard Client
                  </Typography>
                  {/* <Typography variant='body1' sx={{ marginBottom: '0.5rem' }}>
                  Version {appVersion}
                </Typography> */}
                </Grid>
                <Grid item>
                  <InputForm
                    name="Email"
                    errors={errors}
                    control={control}
                    required
                    placeholder="Email"
                    maxLength={100}
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    showStartAdornment
                    startAdornment={<MailOutlineIcon />}
                    sxFC={{ marginBottom: "1rem" }}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item>
                  <InputForm
                    name="Password"
                    errors={errors}
                    control={control}
                    type={isShowPass ? "text" : "password"}
                    placeholder="Password"
                    maxLength={127}
                    showEndAdornment
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    endAdornment={endAdornment}
                    onClickEndAdornment={toggleShowPassword}
                    required
                    showStartAdornment
                    startAdornment={<LockIcon />}
                    sxFC={{ marginBottom: "1.5rem" }}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        Login
                      </LoadingButton>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        Login
                      </Button>
                    </ThemeProvider>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
