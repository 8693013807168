import { createSlice } from "@reduxjs/toolkit";
import { getToken, setRefreshToken, setToken } from "../../../utils/storage";
import { userApiSlice } from "../service/userApiSlice";

  export interface ISubmitLogin {
    rejectMessage: string;
    isLoading: boolean;
    name: string;
    roleTitle: string;
    accesToken: string;
    adminRole: any;
    accessSidebar: any;
    // ocspConfig: any;
  }
  
  const initialState: ISubmitLogin = {
    rejectMessage: "",
    isLoading: false,
    name: "",
    roleTitle: "",
    accesToken: getToken(),
    adminRole: {},
    accessSidebar: [],
    // ocspConfig: initialAccess,
  };

  
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      removeToken: (state) => {
        state.accesToken = "";
      }
    },
    extraReducers(builder) {
      builder.addMatcher(
        userApiSlice.endpoints.submitLogin.matchFulfilled,
        (state: any, action: any) => {
          console.log('action payload data access ===> ', action.payload.access_token)
          console.log('action payload refresh token ===> ', action.payload.refresh_token)
          const token = action.payload.access_token;
          const refresh_token = action.payload.refresh_token;
          state.accesToken = token
          setToken(token);
          setRefreshToken(refresh_token)
        }
      );
    }
  });
    
export const { removeToken } = userSlice.actions;

export const userReducer = userSlice.reducer