import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { IOpenMainProps } from '../../../interfaces/IUtils';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { clearStorage } from '../../../utils/storage';
import { useAppDispatch } from '../../../store';
import { removeToken } from '../../../store/feature/reducer/userReducer';
import { useLogoutMutation } from '../../../store/feature/service/userApiSlice';

const drawerWidth = 320;
const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [offset, setOffset] = useState(0);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [logout, {data}] = useLogoutMutation()
  const { pathname } = useLocation();
  const param = useParams();
  const breadcrumb: any = [{ label: 'Dashboard', href: '/' }];

  // REGION: BREADCRUMB NON DYMANIC
  if (pathname === '/satpas-device') {
    breadcrumb.push({ label: 'Satpas Device', href: '/satpas-device' });
  } else if (pathname === '/satpas-device/create') {
    breadcrumb.push({ label: 'Satpas Device', href: '/satpas-device' });
    breadcrumb.push({ label: 'Create Satpas Device ', href: '/satpas-device/create' });
  } else if (pathname === `/satpas-device/edit/${param.id}`) {
    breadcrumb.push({ label: 'Satpas Device', href: '/satpas-device' });
    breadcrumb.push({ label: 'Update Satpas Device ', href: `/satpas-device/edit/${param.id}` });
  }

  else if (pathname === '/license-server') {
    breadcrumb.push({ label: 'License Server', href: '/license-server' });
  } else if (pathname === '/license-server/create') {
    breadcrumb.push({ label: 'License Server', href: '/license-server' });
    breadcrumb.push({ label: 'Create License Server ', href: '/license-server/create' });
  } else if (pathname === `/license-server/license-detail/${param.id}`) {
    breadcrumb.push({ label: 'License Server', href: '/license-server' });
    breadcrumb.push({ label: 'Detail License Server ', href: `/license-server/license-detail/${param.id}` });
  } else if (pathname === `/license-server/license-detail/${param.id}/create`) {
    breadcrumb.push({ label: 'License Server', href: '/license-server' });
    breadcrumb.push({ label: 'Detail License Server ', href: `/license-server/license-detail/${param.id}` });
    breadcrumb.push({ label: 'Create License Server Detail', href: `/license-server/license-detail/${param.id}/create` });
  }
  
  else if (pathname === '/my-subscription/card') {
    breadcrumb.push({ label: 'My Subscription', href: '/my-subscription' });
  } else if (pathname === '/my-subscription/create') {
    breadcrumb.push({ label: 'My Subscription', href: '/my-subscription' });
    breadcrumb.push({ label: 'Create My Subscription ', href: '/my-subscription/create' });
  } else if (pathname === `/my-subscription/license-detail/${param.id}`) {
    breadcrumb.push({ label: 'My Subscription', href: '/my-subscription' });
    breadcrumb.push({ label: 'Detail My Subscription ', href: `/my-subscription/license-detail/${param.id}` });
  } else if (pathname === `/my-subscription/license-detail/${param.id}/create`) {
    breadcrumb.push({ label: 'My Subscription', href: '/my-subscription' });
    breadcrumb.push({ label: 'Detail My Subscription ', href: `/my-subscription/license-detail/${param.id}` });
    breadcrumb.push({ label: 'Create My Subscription Detail', href: `/my-subscription/license-detail/${param.id}/create` });
  }

  else if (pathname === '/log-events') {
    breadcrumb.push({ label: 'Log Events', href: '/log-events' });
  }

  else if (pathname === '/log-result') {
    breadcrumb.push({ label: 'Log Result', href: '/log-result' });
  }
  
  else if (pathname === '/admin-management') {
    breadcrumb.push({ label: 'Admin Management', href: '/admin-management' });
  } else if (pathname === '/admin-management/create') {
    breadcrumb.push({ label: 'Admin Management', href: '/admin-management' });
    breadcrumb.push({ label: 'Create Admin Management ', href: '/admin-management/create' });
  } else if (pathname === `/admin-management/edit/${param.id}`) {
    breadcrumb.push({ label: 'Admin Management', href: '/admin-management' });
    breadcrumb.push({ label: 'Update Admin Management ', href: `/admin-management/edit/${param.id}` });
  } else if (pathname === `/admin-management/change-password/${param.id}`) {
    breadcrumb.push({ label: 'Admin Management', href: '/admin-management' });
    breadcrumb.push({ label: 'Change Password ', href: `/admin-management/change-password/${param.id}` });
  }
  
  else if (pathname === '/liveness-device') {
    breadcrumb.push({ label: 'Liveness Check by Device', href: '/liveness-device' });
  }

  useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    if (setting === 'Logout') {
      logout({})
      dispatch(removeToken());
      navigate('/login', { replace: true });
      clearStorage();
    }
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: 'flex',
        background: 'transparent',
        // background: 'yellow',
        boxShadow: 'none',
        // backgroundColor: '#B3c100',
        alignItems: 'space-between',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: 'none', paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 3.7,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
        
        {
          offset <= 30 &&
          <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            backgroundColor:'transparent',
            flex:1,
            marginTop:'12px',
            cursor: 'pointer'
            }}
          >
            {breadcrumb.map((item: any, index: Number) => {
              return index != breadcrumb.length-1 ? (
                <Link
                  key={item.label}
                  onClick={() => navigate(item.href)}
                  underline="hover">
                  {item.label}
                </Link>
              ) : (
                <Typography color="textPrimary">
                  {item.label}
                </Typography>
              )
            })}
          </Breadcrumbs>
        }

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                {/* <Typography textAlign="center">{setting}</Typography> */}
                <Button onClick={() => handleButton(setting)}>{setting}</Button>
              </MenuItem>
            ))}
          </Menu>

        </Box>
        {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer */}
        {/* </Typography> */}
        {/* <Navbar /> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;
