import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DnsIcon from '@mui/icons-material/Dns';
import DvrIcon from '@mui/icons-material/Dvr';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  },
  // { 
  //   key: 'satpas-device',
  //   name: 'Satpas Device',
  //   path: '/satpas-device',
  //   icon: <FeaturedPlayListIcon />,
  // },
  { 
    key: 'my-subscription/card',
    name: 'My Subscription',
    path: '/my-subscription/card',
    icon: <SubscriptionsIcon />,
  },
  // { 
  //   key: 'log',
  //   name: 'Log',
  //   path: '',
  //   icon: <DvrIcon />,
  //   children: [
  //     { 
  //       key: 'log-events',
  //       name: 'Log Events',
  //       path: '/log-events',
  //       icon: <DvrIcon />,
  //     },
  //     { 
  //       key: 'log-result',
  //       name: 'Log Result',
  //       path: '/log-result',
  //       icon: <DvrIcon />,
  //     },
  //   ]
  // },
  // { 
  //   key: 'admin-management',
  //   name: 'Admin Management',
  //   path: '/admin-management',
  //   icon: <ManageAccountsIcon />,
  // },
  // { 
  //   key: 'liveness-device',
  //   name: 'Liveness Check by Device',
  //   path: '/liveness-device',
  //   icon: <DevicesOtherIcon />,
  // },
];
